/*==============================
 *  Utilities
=================================*/
/*====================================
    Google Font  
===================================*/

@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900|Raleway:400,400i,500,500i,600,600i,700,700i,800&display=swap");

/*----------------------------------------
01. font-family: 'Raleway', sans-serif;
02. font-family: 'Lato', sans-serif;

-------------------------------------*/

.hidden {
	display: none;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.poss_relative {
	position: relative;
}

.poss_absolute {
	position: absolute;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

.invisible {
	visibility: hidden;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.wrapper {
	position: relative;
}

/* Define Colors */
.theme-color {
	color: $theme-color;
}

/*===============================
    Background Color 
=================================*/

@include config-bg-colors("bg_color--", "1" $white, "2" $theme-color);

/*===========================
    Background Image 
=============================*/
.bg_image {
	@extend %bgImagePosition;
}

.bg_image--1 {
	background-image: url("../../images/bg/bg-image-1.png");
}

.bg_image--2 {
	background-image: url("../../images/bg/bg-image-2.png");
}

@for $i from 1 through 80 {
	.font--#{$i} {
		font-size: #{$i}px !important;
	}
}

/*=========================
    Text specialized 
==========================*/
.text-italic {
	font-style: italic;
}

.text-normal {
	font-style: normal;
}

.text-underline {
	font-style: underline;
}

/* Height and width */
.fullscreen {
	min-height: 100vh;
	width: 100%;
}

/*===================
Custom Row
======================*/
.row--0 {
	margin-left: -0px;
	margin-right: -0px;

	& > [class*="col"] {
		padding-left: 0px;
		padding-right: 0px;
	}
}

.row--5 {
	margin-left: -5px;
	margin-right: -5px;

	& > [class*="col"] {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.row--10 {
	margin-left: -10px;
	margin-right: -10px;

	& > [class*="col"] {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.row--20 {
	margin-left: -20px;
	margin-right: -20px;

	// Responsive
	@media #{$laptop-device} {
		margin-left: -15px;
		margin-right: -15px;
	}

	@media #{$lg-layout} {
		margin-left: -15px;
		margin-right: -15px;
	}

	@media #{$md-layout} {
		margin-left: -15px;
		margin-right: -15px;
	}

	@media #{$sm-layout} {
		margin-left: -15px !important;
		margin-right: -15px !important;
	}

	& > [class*="col"],
	& > [class*="col-"] {
		padding-left: 20px;
		padding-right: 20px;

		// Responsive
		@media #{$laptop-device} {
			padding-left: 15px;
			padding-right: 15px;
		}

		@media #{$lg-layout} {
			padding-left: 15px;
			padding-right: 15px;
		}

		@media #{$md-layout} {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}

		@media #{$sm-layout} {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}
}

/*===========================
    Input Placeholder
=============================*/
input:-moz-placeholder,
textarea:-moz-placeholder {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*=============================
	Overlay styles 
==============================*/

[data-overlay],
[data-black-overlay],
[data-white-overlay] {
	position: relative;
}

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
	position: relative;
	z-index: 1;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
}

[data-overlay]:before {
	background-color: $theme-color;
}

[data-black-overlay]:before {
	background-color: #000000;
}

[data-white-overlay]:before {
	background-color: #ffffff;
}

@for $i from 1 through 10 {
	[data-overlay="#{$i}"]:before,
	[data-black-overlay="#{$i}"]:before,
	[data-white-overlay="#{$i}"]:before {
		opacity: #{$i * 0.1};
	}
}

/*------------------------------
    Scroll Up 
--------------------------------*/
#scrollUp {
	width: 40px;
	height: 40px;
	background-color: $theme-color;
	border: 3px solid #eeeeee;
	color: $heading-color;
	right: 20px;
	bottom: 60px;
	text-align: center;
	overflow: hidden;
	border-radius: 50px;
	z-index: 9811 !important;
	@media #{$sm-layout} {
		display: none !important;
	}

	& i {
		display: block;
		line-height: 34px;
		font-size: 22px;
	}

	&:hover {
		& i {
			animation-name: fadeInUp;
			animation-duration: 1s;
			animation-fill-mode: both;
			animation-iteration-count: infinite;
		}
	}
}

// Contact Form
.form-message {
	margin-bottom: 0;
	text-align: center;

	&.error {
		margin-top: 20px;
		color: #f80707;
	}

	&.success {
		margin-top: 20px;
		color: #0d8d2d;
	}
}
