/*======================
    Blog Area 
========================*/

.blog-area {
	position: relative;
	z-index: 2;
}
.blog {
	.thumb {
		img {
		}
	}
	.inner {
		text-align: center;

		h3.title {
			a {
				line-height: 1.4074;
				color: #2f2f2f;
			}
		}

		.desc {
			font-size: 16px;
			line-height: 1.71429;
		}

		.blog-btn {
			.button-link {
				border: 1px solid #d1d1d1;
				padding: 12px 40px;
				color: #2f2f2f;
				background-color: rgba(255, 255, 255, 0);
				line-height: 20px;
				font-size: 13px;
				display: inline-block;
				cursor: pointer;
				transition: all 0.5s;

				&:hover {
					color: #fff;
					background-color: $theme-color;
					border-color: $theme-color;
				}
			}
		}
	}
}

/*========================
Pagination Area 
===========================*/
.page-list {
	@extend %liststyle;
	margin: 0 -3px;
	li {
		display: inline-block;
		margin: 0 3px;
		a {
			font-size: 12px;
			text-align: center;
			display: block;
			position: relative;
			background: #fff;
			border: 1px solid #e0e0e0;
			padding: 15px 10px;
			line-height: 20px;
			min-width: 50px;
			transition: all 0.3s;
			display: inline-block;
			@extend %transition;
			@extend %bodyColor;

			&:hover {
				background-color: #2f2f2f;
				color: #fff;
				border-color: transparent;
			}
		}
		&.active {
			a {
				background-color: #2f2f2f;
				color: #fff;
				border-color: transparent;
			}
		}
	}
}
