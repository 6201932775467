/* CookiePolicyPage.css */
.cookie-policy-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.cookie-policy-content {
    background-color: white;
    padding: 40px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-button {
    float: right;
    font-size: 1.5rem;
    cursor: pointer;
}

.close-button:hover {
    color: red;
}