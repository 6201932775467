/*=====================
    Download Area 
=======================*/

.download-area {
	position: relative;

	padding-top: 358px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 60px;
	position: relative;
	z-index: 2;
	// background-image: url(/assets/images/bg/bg-image-1.png);

	@media #{$lg-layout} {
		margin-top: -347px;
		padding-bottom: 308px;
		padding-top: 436px;
	}
	@media #{$md-layout} {
		transform: rotate(0deg);
		background-image: inherit;
		margin-top: 0;
		padding-bottom: 100px;
		padding-top: 100px;
	}
	@media #{$sm-layout} {
		transform: rotate(0deg);
		background-image: inherit;
		margin-top: 0;
		padding-bottom: 100px;
		padding-top: 100px;
	}

	// .bg-overlay {
	// 	background: rgba(222, 22, 0, 0.85) none repeat scroll 0 0;
	// 	content: "";
	// 	height: 100%;
	// 	left: 0;
	// 	position: absolute;
	// 	top: 0;
	// 	width: 100%;
	// 	z-index: -1;
	// }

	// &::before {
	// 	background-image: url(/assets/images/app/shape-2.png);
	// 	background-repeat: no-repeat;
	// 	background-position: left top;
	// 	background-size: cover;
	// 	content: "";
	// 	left: 0;
	// 	position: absolute;
	// 	top: 0;
	// 	width: 100%;
	// 	z-index: 2;
	// }
	// &::after {
	// 	background-image: url(/assets/images/app/shape-3.png);
	// 	background-repeat: no-repeat;
	// 	background-position: center;
	// 	background-size: cover;
	// 	content: "";
	// 	right: 0;
	// 	position: absolute;
	// 	bottom: -1px;
	// 	width: 100%;
	// 	z-index: 1;
	// 	-webkit-transform: rotate(180deg);
	// 	-ms-transform: rotate(180deg);
	// 	transform: rotate(180deg);
	// }
	// &::before,
	// &::after {
	// 	height: 460px;

	// 	@media #{$md-layout} {
	// 		height: auto;
	// 	}

	// 	@media #{$sm-layout} {
	// 		height: auto;
	// 	}
	// }

	&.horizontal {
		margin-top: 0;
		padding-bottom: 100px;
		padding-top: 100px;
		position: relative;
		z-index: 2;
		background-image: url(/assets/images/bg/bg-image-1.png);
		margin-bottom: 77px;
		&::before,
		&::after {
			height: auto;
		}
		&::before {
			background-image: inherit;
		}
		&::after {
			transform: rotate(0deg);
			background-image: inherit;
		}

		@media #{$sm-layout} {
			margin-bottom: 0;
		}
	}
}

.download-buttons {
	text-align: start;
	.download-btn {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #ffffff;
		border-radius: 2px;
		height: 78px;
		margin-right: 20px;
		padding: 10px 29px 0 87px;
		position: relative;
		-webkit-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
		display: inline-block;
		margin-bottom: 20px;
		@media #{$sm-layout} {
			text-align: center; /* Align button text to center on mobile view */
			margin-right: 0; /* Remove right margin on mobile view */
			margin-bottom: 20px;
			padding-right: 50px;
			padding-left: 95px;
		}
		i {
			color: #ffffff;
			float: left;
			font-size: 50px;
			left: 22px;
			position: absolute;
			width: 53px;
		}
		span {
			color: #ffffff;
			display: block;
			font-family: $heading-font;
			font-size: 18px;
			line-height: 26px;
			text-align: left;

			@media #{$sm-layout} {
				font-size: 13px;
			}

			span {
				&.large-text {
					display: block !important;
					font-weight: 700;
					font-size: 26px;
					position: relative;
					top: -4px;
					color: #ffffff;
				}
			}
		}
	}
	@media (max-width: 768px) {
		display: block;
		margin: 0 auto;
		text-align: center;
	}
}
