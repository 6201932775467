// All Css Here
@import "~bootstrap/dist/css/bootstrap.min.css";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "./assets/css/plugins.css";
@import "./assets/scss/style";

@media (max-width: 576px) {
	.col-12 {
		width: auto !important;
	}

	// .d-flex {
	// 	gap: 5px;
	// }
}
